<template>
    <div class="services">
        <div class="header">
            <h1>{{ $t('services.names.title') }}</h1>
            <button class="create-button" @click="createService">{{ $t('services.buttons.create') }}</button>
        </div>
        <div class="service-list">
            <div>
                <div v-for="service in services" :key="service.id" class="service-card">
                    <div class="card-header">
                        <h2>{{ service.attributes.name }}</h2>
                        <div v-if="service.attributes.type != 'BasicCompanyService'" class="action-buttons">
                            <button class="edit-button" @click="editService(service.id)">
                                {{ $t('services.buttons.edit') }}
                            </button>
                            <button class="delete-button" @click="confirmDelete(service.id)">
                                {{ $t('services.buttons.delete') }}
                            </button>
                        </div>
                    </div>
                    <p>{{ service.attributes.description }}</p>
                    <div class="buttons">
                        <button class="more-button" @click="viewMore(service)">
                            {{ $t('services.buttons.more') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showDeleteModal" class="modal">
            <div class="modal-content">
                <p>{{ $t('services.names.flashMessage') }}</p>
                <div class="modal-buttons">
                    <button class="cancel-button" @click="cancelDelete">{{ $t('services.buttons.cancel') }}</button>
                    <button class="confirm-button" @click="deleteService(confirmedServiceId)">
                        {{ $t('services.buttons.confirm') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Service from '../../services/services.service';
    export default {
        name: 'Services',
        data() {
            return {
                services: [],
                Service: new Service(),
                showDeleteModal: false,
                confirmedServiceId: null,
            };
        },

        created() {
            this.fetchServices();
        },
        methods: {
            async fetchServices() {
                try {
                    const { data } = await this.Service.getAll();

                    this.services = data.data;
                } catch {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Service request list errors');
                    }
                }
            },
            createService() {
                this.$router.push({ name: 'services.new' });
            },
            viewMore(service) {
                this.$router.push({ path: `view/${service.id}` });
            },
            editService(serviceId) {
                this.$router.push({ path: `edit/${serviceId}` });
            },
            confirmDelete(serviceId) {
                this.confirmedServiceId = serviceId;
                this.showDeleteModal = true;
            },
            cancelDelete() {
                this.showDeleteModal = false;
                this.confirmedServiceId = null;
            },
            async deleteService(serviceId) {
                try {
                    await this.Service.destroy(serviceId);
                    this.services = this.services.filter(service => service.id !== serviceId);
                    this.showDeleteModal = false;
                } catch {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Error deleting service');
                    }
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .services {
        padding: 20px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .header h1 {
        margin: 0;
    }

    .header button {
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
    }

    .service-list {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .service-card {
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        width: 100%;
        position: relative;
        margin-top: 10px;

        .theme-dark & {
            border-color: #333;
        }
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .action-buttons {
        display: flex;
        gap: 10px;
    }

    .service-card h2 {
        margin: 0 0 10px;
    }

    .service-card p {
        margin: 0 0 10px;
        .theme-dark & {
            color: #c4c4cf;
        }
    }

    .buttons {
        display: flex;
        gap: 10px;
    }

    .create-button {
        padding: 10px 20px;
        font-size: 14px;
        cursor: pointer;
        background-color: #007bff;
        color: white;
        border: 1px solid #c4c4cf;
        border-radius: 4px;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }

        .theme-dark & {
            color: #ffa500;
            border-color: #333;
            background-color: rgb(40, 40, 40);

            &:hover {
                color: #c4c4cf;
                border-color: #c4c4cf;
            }
        }
    }

    .more-button,
    .edit-button,
    .delete-button {
        padding: 10px 20px;
        font-size: 14px;
        cursor: pointer;
        color: white;
        border: none;
        border-radius: 4px;
        transition: background-color 0.3s;

        &:hover {
            color: #c4c4cf;
        }
    }

    .more-button {
        background-color: #007bff;

        &:hover {
            background-color: #0056b3;
        }

        .theme-dark & {
            color: #ffa500;
            background-color: rgb(40, 40, 40);

            &:hover {
                background-color: #555;
                color: #c4c4cf;
            }
        }
    }

    .edit-button {
        background-color: #ffc107;

        &:hover {
            background-color: #e0a800;
        }

        .theme-dark & {
            color: #ffa500;
            background-color: rgb(40, 40, 40);

            &:hover {
                background-color: #555;
                color: #c4c4cf;
            }
        }
    }

    .delete-button {
        background-color: #dc3545;

        &:hover {
            background-color: #c82333;
        }

        .theme-dark & {
            color: #ffa500;
            background-color: rgb(40, 40, 40);

            &:hover {
                background-color: #555;
                color: #c4c4cf;
            }
        }
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-content {
        background: white;
        padding: 20px;
        border-radius: 8px;
        text-align: center;

        .theme-dark & {
            background-color: #333;

            & p {
                color: #ffa500;
            }
        }
    }

    .modal-buttons {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 20px;
    }

    .cancel-button {
        padding: 10px 20px;
        font-size: 14px;
        cursor: pointer;
        background-color: #6c757d;
        color: white;
        border: none;
        border-radius: 4px;

        &:hover {
            background-color: #5a6268;
        }
    }

    .confirm-button {
        padding: 10px 20px;
        font-size: 14px;
        cursor: pointer;
        background-color: #dc3545;
        color: white;
        border: none;
        border-radius: 4px;

        &:hover {
            background-color: #c82333;
        }
    }
</style>
