<template>
    <div class="service-detail">
        <h1>{{ service.name }}</h1>
        <p>{{ service.description }}</p>
        <button @click="goBack">{{ $t('services.buttons.back') }}</button>
    </div>
</template>

<script>
    import Service from '../../services/services.service';
    export default {
        data() {
            return {
                service: null,
                Service: new Service(),
            };
        },
        created() {
            const serviceId = this.$route.params.id;
            this.fetchService(serviceId);
        },
        methods: {
            async fetchService(id) {
                try {
                    const { data } = await this.Service.getById(id);
                    this.service = data.data.attributes;
                } catch {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Get By ID Service errors');
                    }
                }
            },
            goBack() {
                this.$router.push({ name: 'services.list' });
            },
        },
    };
</script>

<style scoped lang="scss">
    .service-detail {
        padding: 40px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        max-width: 800px;
        margin: 40px auto;
        text-align: left;
        transition: all 0.3s ease-in-out;
        float: left;
        .theme-dark & {
            background-color: #333;
            color: #ffa500;
        }
        &:hover {
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        }
    }

    .service-detail h1 {
        font-size: 2.5em;
        margin-bottom: 20px;
        color: #333;
        .theme-dark & {
            color: #ffa500;
        }
    }

    .service-detail p {
        font-size: 1.2em;
        margin-bottom: 30px;
        color: #555;
        line-height: 1.6;
        .theme-dark & {
            color: #c4c4cf;
        }
    }

    .service-detail button {
        padding: 12px 30px;
        font-size: 1.1em;
        cursor: pointer;
        background-color: #007bff;
        border: 1px solid #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        transition: all 0.3s ease;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background-color: #222;
            border: 1px solid #222;
            color: #ffa500;
        }

        &:hover {
            background-color: #0056b3;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

            .theme-dark & {
                background-color: #222;
                border: 1px solid #c4c4cf;
                color: #c4c4cf;
            }
        }
    }
</style>
